@import './utility/index.scss';

.infoContainer {
	width: 100%;
	// margin: 0px 16px 0px 0px;
	box-sizing: border-box;
	border: 1px dashed $color-grey-light;
	border-radius: 4px;
	// padding: 16px;
	// line-height: 170%;
	&_half {
		width: 50%;
		color: $color-grey;
		font-weight: 400;

		&_title {
			color: $color-grey;
			font-weight: 700;
		}
	}

	&_table {
		width: 100%;
		margin-top: 16px;
	}
}

.btnHolder {
	margin: 24px 0;
}

.Clientmodal {
	&_spanContainingRow {
		color: $color-grey;
	}
}
