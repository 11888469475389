@import './utility/index.scss';

.tableInputs {
	width: 384px;

	@media only screen and (max-width: 1024px) {
		width: 100%;
		margin-bottom: 10px;
	}

	@media only screen and (max-width: 768px) {
		width: 100%;
		margin-bottom: 10px;
	}
}

.fullw {
	width: 100%;
}

//client info repayments
.inputContainer > * + * {
	margin-left: 10px;

	// @media only screen and (max-width: 1024px) {
	// 	margin-left: 0;
	// }

	// @media only screen and (max-width: 768px) {
	// 	margin-left: 0;
	// }

	@media (max-width: $breakpoint-mobile) {
		margin: 10px 0 0 0;
	}
}

.repamentsButtonContainer {
	margin: 1rem 0 0 0;
	display: flex;

	@media (max-width: $breakpoint-mobile) {
		flex-direction: column;
	}
}

.repamentsButtonContainer > * + * {
	margin-left: 10px;

	@media (max-width: $breakpoint-mobile) {
		margin-left: 0;
		margin-top: 10px;
	}
}

.Clientmodal {
	&_spanContainingRow {
		color: $color-grey;
	}
}

.sourceButton {
	box-sizing: border-box;
	border-radius: 4px;
	padding: 4px 8px;
	text-align: center;
	margin: 0 0 8px 0;
}

.statusButton {
	color: $color-dark-yellow;
	background: $color-light-green-white;
	border: 1px solid $color-dark-yellow;
	box-sizing: border-box;
	border-radius: 4px;
	padding: 4px 8px;
	text-align: center;
	margin: 8px 0;
}

.ActiveButton {
	background: $color-vanilla;
	border: 1px solid $color-ligh-green;
	border-radius: 4px;
	color: $color-ligh-green;
	text-align: center;

	&:hover {
		color: $color-ligh-green;
		background: $color-vanilla;
		border: 1px solid $color-ligh-green;
	}
}

.transactionDetailsformContent {
	color: $color-grey;
	line-height: 170%;
}

.infoContainer_table {
	overflow: auto;
}

// filter css
.filterContainer > * + * {
	margin-right: 10px;
}

.newtableInputs {
	width: 150px;
	margin-right: 10px;
}

.cardsList > * + * {
	margin: 8px 8px;
}

